import { getAuthUser } from '#imports';

export default defineNuxtRouteMiddleware((from, to) => {
  const authUser = getAuthUser();
  logger().debug('[middleware] verified', { from: from.fullPath, to: to.fullPath, userId: authUser?.id, emailVerifiedAt: authUser?.emailVerifiedAt });

  if (!(authUser?.emailVerifiedAt)) {
    const query: Record<string, any> = {};
    if (to.path.indexOf('/auth/') !== 0) {
      query.redirect = to.fullPath;
    }
    return navigateTo({
      path: '/auth/verify/email',
      query,
    });
  }
});
